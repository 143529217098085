@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.marquee {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100vw;
  }
  
  .marquee-content {
    display: inline-block;

    animation: marquee 4s linear infinite alternate; /* Adjust the timing to your liking */
    font-family: 'Oswald', sans-serif;
  }
  
  
  @keyframes marquee {
    0% {
      transform: translateX(25%);
    }
    100% {
      transform: translateX(75%);
    }
  }


