
.Container
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10000000000;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(131, 131, 131, 0.7);

    border-radius: 0px !important;

    cursor: alias;
}

.FormContainer
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 60%;

    cursor: auto;

    padding: 20px;
    background: rgb(45, 46, 72);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
}

.FormElementPosition
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.Text
{
    color: rgb(255, 255, 255);
}

.FL
{

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: rgba(255, 255, 255, 0.7);
}

.IMG
{
    width: 20px;
    height: 20px;
}

.Input
{
    border-radius: 4px;
    border: 1px solid rgb(173, 173, 173);
    margin-bottom: 15px;
    box-sizing: border-box;
    padding: 2px;
    outline: none;
    line-height: 1.2;
}
.Input:focus {
    outline: none;
    border: 2px solid #0A64BC; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(10, 100, 188, 0.5); /* Subtle glow */
}

.Banner
{
    height: 2vh;
    background-color: #0A64BC;
    border: none;
    margin-left: 0; 
}

.Submit
{
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    background-color: #0A64BC
}
.Submit:hover
{
    background-color: #084a8a; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: white;
}

ul {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.5;
}

ul li {
    margin-bottom: 8px; 
}

.BannerStyled
{
    width: 100%;

}

.Close
{
    padding: 0;
    margin: 0;
    position: absolute;
    cursor: pointer;
    color: red;
    right: 0%;
    top: 0%;
}