
.Container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    pointer-events: all;
    overflow-y: scroll;
}


.Header
{
    margin-left: 20px;
    color: white;
    font-size: 40px;
}

.Text1
{
    color: white;
    font-size: 14px;
    width: 90%;
}

.HeaderContainer
{
    display: flex;
    flex-direction: row;
    width:  100%;
    height: 150px;

    gap: 10%;

    align-items: center;
    justify-content: center;
}

.Button
{
    color: white;
    border: none;
    background-color: rgb(90, 90, 90);

    cursor: pointer;
}

.Button:hover
{
    color: rgb(170, 170, 170);
}

.NotSelectedButton
{
    background-color: rgb(52, 52, 52);

}

.ButtonLeft
{
    border-right: 1px solid white;
}

.SelectionContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}


@media(max-width: 510px)
{
    .ButtonLeft
    {
        border: none;
    }
}