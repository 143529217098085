.CoolerMenuContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    margin-left: 2vw;
    z-index: 13019004;
    background: none;
    pointer-events: all;
    top: 50%;
    transform: translateY(-50%);
}

.Row
{
    display: flex;
    flex-direction: row;
    margin-left: 0vw;
    margin-top: 3vh;
    opacity: 0;
    
}

.PermBoxShadow
{
    box-shadow: 0 0 5px #b4bfd9, 0 0 5px #b4bfd9, 0 0 8px #b4bfd9, 0 0 14px #b4bfd9;
}

.CoolerMenuItem
{
    pointer-events: all;
    background: none;

    z-index: 100000000;

    width: 5vw;
    height: 5vw;
    border-radius: 200px;
    .img
    {
        width: 5vw;
    }
}

.DescriptorText
{
    margin-top: 0vh;
    font-size: .85vmax;
    color: white;
    position: absolute;
    width: 10vw;
    margin-left: 6.5vw;
    opacity: 0;
    cursor: default;
}

.DescriptorTextLong
{
    margin-top: 0vh;
    font-size: .85vmax;
    color: white;
    position: absolute;
    width: 10vw;
    margin-left: 6vw;
    opacity: 0;
    cursor: default;
}

@media(max-aspect-ratio: 17/10) and (min-aspect-ratio: 15/10)
{
    .CoolerMenuItem
    {
        width: 6vw;
        height: 6vw;
        .img
        {
            width: 6vw;
        }
    }

    .DescriptorText
    {
        font-size: 1.15vmax;
        width: 13vw;
        margin-left: 9.5vw;
    }

    .DescriptorTextLong
    {
        font-size: 1.15vmax;
        width: 10vw;
        margin-left: 9.5vw;
    }
}
@media(max-aspect-ratio: 15/10) and (min-aspect-ratio: 14/10)
{
    .CoolerMenuItem
    {
        width: 7vw;
        height: 7vw;
        .img
        {
            width: 7vw;
        }
    }

    .DescriptorText
    {
        font-size: 1.15vmax;
        width: 13vw;
        margin-left: 9.5vw;
    }

    .DescriptorTextLong
    {
        font-size: 1.15vmax;
        width: 10vw;
        margin-left: 9.5vw;
    }
}

@media(max-aspect-ratio: 14/10) and (min-aspect-ratio: 13/10)
{
    .CoolerMenuItem
    {
        width: 7.5vw;
        height: 7.5vw;
        .img
        {
            width: 7.5vw;
        }
    }

    .DescriptorText
    {
        font-size: 1.15vmax;
        width: 13vw;
        margin-left: 9.5vw;
    }

    .DescriptorTextLong
    {
        font-size: 1.15vmax;
        width: 10vw;
        margin-left: 9.5vw;
    }
}

@media(max-aspect-ratio: 13/10) and (min-aspect-ratio: 12/10)
{
    .CoolerMenuItem
    {
        width: 7.5vw;
        height: 7.5vw;
        .img
        {
            width: 7.5vw;
        }
    }

    .DescriptorText
    {
        font-size: 1.15vmax;
        width: 13vw;
        margin-left: 9.5vw;
    }

    .DescriptorTextLong
    {
        font-size: 1.15vmax;
        width: 10vw;
        margin-left: 9.5vw;
    }
}

@media(max-aspect-ratio: 12/10) and (min-aspect-ratio: 11/10)
{
    .CoolerMenuItem
    {
        width: 7.5vw;
        height: 7.5vw;
        .img
        {
            width: 7.5vw;
        }
    }

    .DescriptorText
    {
        font-size: 1.15vmax;
        width: 13vw;
        margin-left: 9.5vw;
    }

    .DescriptorTextLong
    {
        font-size: 1.15vmax;
        width: 10vw;
        margin-left: 9.5vw;
    }
}

@media(max-aspect-ratio: 11/10)
{
    .CoolerMenuItem
    {
        width: 7.5vw;
        height: 7.5vw;
        .img
        {
            width: 7.5vw;
        }
    }

    .DescriptorText
    {
        font-size: 1.3vmax;
        width: 15vw;
        margin-left: 10.5vw;
    }

    .DescriptorTextLong
    {
        font-size: 1.3vmax;
        width: 15vw;
        margin-left: 10.5vw;
    }
}

/*
    Portrait mode is a separate type of menu 
    (horizontal menu) at the bottom of the screen 
    rather than the left
*/
@media (orientation: portrait)
{
    .CoolerMenuText
    {
        font-size: 0px;
        position: absolute;
        visibility: hidden;
    }
    
    .DescriptorText
    {
        font-size: 0px;
        position: absolute;
        visibility: hidden;
    }

    @media(min-aspect-ratio: 5/10)
    {
        .CoolerMenuContainer
        {
            margin-left: 0vw;
            top: 83%;
            flex-direction: row;
            margin-bottom: 2vh;
            right: 50%;
            transform: translate(50%, 0);
        }
    
    
        .PermBoxShadow
        {
            box-shadow: 0 0 5px #b4bfd9, 0 0 5px #b4bfd9, 0 0 8px #b4bfd9, 0 0 14px #b4bfd9;
        }
        
        .CoolerMenuItem
        {
            pointer-events: all;
            background: none;
        
            width: 9vh;
            height: 9vh;
            border-radius: 200px;
    
            .img
            {
                width: 9vh;
            }
        
        }
    }
    @media(min-aspect-ratio: 4.5/10) and (max-aspect-ratio: 4.9999/10)
    {
        .CoolerMenuContainer
        {
            margin-left: 0vw;
            top: 83%;
            flex-direction: row;
            margin-bottom: 2vh;
            right: 50%;
            transform: translate(50%, 0);
        }

        .PermBoxShadow
        {
            box-shadow: 0 0 5px #b4bfd9, 0 0 5px #b4bfd9, 0 0 8px #b4bfd9, 0 0 14px #b4bfd9;
        }
        
        .CoolerMenuItem
        {
            pointer-events: all;
            background: none;
        
            width: 8.25vh;
            height: 8.25vh;
            border-radius: 200px;
    
            .img
            {
                width: 8.25vh;
            }
        
        }
    }

    @media(max-aspect-ratio: 4.49999/10)
    {
        .CoolerMenuContainer
        {
    
            margin-left: 0vw;
            top: 83%;
            flex-direction: row;
            margin-bottom: 2vh;
            left: 50%;
            transform: translate(-50%, 0);
        }
    
    
        .PermBoxShadow
        {
            box-shadow: 0 0 5px #b4bfd9, 0 0 5px #b4bfd9, 0 0 8px #b4bfd9, 0 0 14px #b4bfd9;
        }
        
        .CoolerMenuItem
        {
            pointer-events: all;
            background: none;
        
            width: 7.5vh;
            height: 7.5vh;
            border-radius: 200px;
    
            .img
            {
                width: 7.5vh;
            }
        
        }
    }

}