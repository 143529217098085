@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap');

.glowingButton
{
    transform: translate(-0%, 0%);
    text-align: center;
    padding: 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    line-height:  6vh;
    color: #bababa;
    background: none;
    font-size: 1.5vh;
    text-transform: uppercase;
    text-decoration: none;
    font-style: "Source Sans Pro, sans-serif";
    box-sizing: border-box;
    background-size: 0%;
    background-size: 400%;
    backdrop-filter: blur(30px);
    border-radius: 5vw;
    border: 0.1vh solid #8a8a8a;

    transition: 2s ease;
}

.glowingButton:hover{
    animation: animate 8s linear infinite;
    cursor: pointer;
    color: transparent;
    transition: 2s ease;
}

.button7 {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
@keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}

.glowingButton::before
{
    content: '';
    text-align: center;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;    
    background: linear-gradient(90deg, #1a2a6c, #3b4cca, #6a00ff, #29ffc6, #1a2a6c);
    background-size: 400%;
    border-radius: 30px;
    opacity: 0;
    transition: 2s ease;
}
.glowingButton:hover:before
{
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    transition: 2s ease;
}


.glowingButton2
{
    transform: translate(-0%, 0%);
    text-align: center;
    padding: 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    line-height:  6vh;
    color: #ffffff;
    background: rgba(93, 135, 177, 0.8);
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-style: "Source Sans Pro, sans-serif";
    box-sizing: border-box;
    background-size: 0%;
    background-size: 400%;
    backdrop-filter: blur(30px);
    border-radius: 5vw;
    border: 0.1vh solid #8a8a8a;

    transition: 2s ease;
}



.glowingButton2:hover{
    background: none;
    animation: animate 8s linear infinite;
    cursor: pointer;
    color: transparent;
    transition: 2s ease;
}

.glowingButton2::before
{
    content: '';
    text-align: center;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;    
    background: linear-gradient(90deg, #03a9f4, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 30px;
    opacity: 0;
    transition: 2s ease;
}
.glowingButton2:hover:before
{
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    transition: 2s ease;
}