
.Container
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: 0;
    z-index: -100;
}

.TextContainer
{
    display: flex;
    flex-direction: column;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    align-items: center;
    z-index: 10;
    margin: 0;
    padding: 0;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    width: 60vw;
    height: 70vh;
}

.Facts
{
    width: 60vw;
}

.Exit
{
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;
    z-index: 10;
    font-size: 40px;
    top: 2.5%;
    right: 2.5%;
    color: #FF6B6B;
    cursor: pointer;
}

@media(max-width: 1000px)
{
    .TextContainer
    {
        width: 70vw;
        height: 70vh;
    }
    .Facts
    {
        width: 70vw;
    }
}

@media(max-width: 1000px) and (min-width: 918px)
{
    .TextContainer
    {
        width: 70vw;
        height: 66vh;
    }
    .Facts
    {
        width: 70vw;
    }
}

@media(max-width: 918px) and (min-width: 718px)
{
    .TextContainer
    {
        top: 45%;

        width: 70vw;
        height: 66vh;
    }

    .Text1
    {
        font-size: 12.5px;
    }
    .Facts
    {
        width: 70vw;
    }
}

@media(max-width: 718px) and (min-width: 585px)
{
    .TextContainer
    {
        top: 45%;

        width: 70vw;
        height: 66vh;
    }
    .Facts
    {
        width: 70vw;
    }

    .Text1
    {
        font-size: 12.5px;
    }

    .Header
    {
        margin: 0;
        margin-top: 20px;
    }
}

@media(max-width: 585px) and (min-width: 420px)
{
    .TextContainer
    {
        top: 42.5%;

        width: 80vw;
        height: 80vh;
    }
    .Facts
    {
        width: 80vw;
    }

    .Text1
    {
        font-size: 12px;
    }

    .Header
    {
        margin: 0;
        margin-top: 20px;
    }
}

@media(max-width: 420px)
{
    .TextContainer
    {
        top: 42.5%;

        width: 80vw;
        height: 80vh;
    }
    .Facts
    {
        width: 80vw;
    }

    .Text1
    {
        font-size: 12px;
    }

    .Header
    {
        margin: 0;
        margin-top: 20px;
    }
    .Exit
    {
        margin: 0;
        padding: 0;
        position: absolute;
        opacity: 0;
        z-index: 10;
        font-size: 40px;
        top: 0%;
        right: 2.5%;
        color: #FF6B6B;
        cursor: pointer;
    }
}

.TLabel
{
    text-align: left;
    width: 90%;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    color: white;
}

.TLabel1
{
    text-align: left;
    width: 90%;
    padding: 0;
    margin: 0;
    margin-top: 25px;
    font-size: 16px;
    color: white;
}
.InnerText
{
    color: #D3D3D3;
}