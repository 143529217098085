.ContainerSection1
{
    margin: 0;
    padding: 0;
    width: 100vw;
    z-index: 1000;
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    flex-direction: column;
    background-color: black;
    /*background-image: url('../../../../assets/testbg.jpg');*/
}


.LeftAlign
{
    width: 80%;
    padding: 20px;
    display: flex;
    margin-top: 30px;
    padding-bottom: 50px;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgb(97, 97, 97);
}

.Header
{
    margin: 0;
    padding: 0;
    color: white;
    font-size: 40px;
    margin-top: 20px;
}

.TopText
{
    margin: 0;
    padding: 0; 
    font-style: italic;  
    color: rgb(185, 185, 185);
}

.TopTextContainer
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.UnderButton
{
    font-size: 16px;
    margin-top: 20px;
    box-shadow: 4px 4px 1px rgb(156, 197, 255);
}

.LowerTextMargin
{
    margin-top: 20px !important;
}

.DividerLine
{
    margin: 0;
    padding: 0;
    height: 1px;
    border: none;
    margin-top: 60px;
    margin-left: -20px;
    margin-bottom: 30px;
    width: calc(100% + 40px);
    background-color: rgb(97, 97, 97);
}