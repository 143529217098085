

.Container
{
    height: 440vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.TextContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30%;
}

.TextContainer span
{
    background-color: #0e0e0f;
    width: 0vw;
    height: 0vh;
    position: absolute;
}

.TextContainer h2
{
    opacity: 0;

    margin: 0;
    padding: 0;

    width: 50%;
    font-size: 50px;
    color: white;

    text-shadow: 1px 1px 2px black;
}

.TextContainer p
{
    opacity: 0;
    margin: 0;
    padding: 0;

    color: white;
    width: 50%;
}

.AxiomContainer
{
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: center;
    gap: 10px;
    top: 40%;
    scale: 0;
}

.Axiom
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 125px;
    width: 300px;

    color: white;
    background: linear-gradient(145deg, #303545, #2a2f3b);
    border: 1px solid grey;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.Axiom:hover{
    background: linear-gradient(145deg, #454c63, #3f4758);
}

.AxiomIcon
{
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 1));
    width: 10vw;
    height: 10vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.AxiomIcon img
{
    margin-top: 10px;
    width: 80px;
    height: 80px;
}

.LongDescription
{
    position: absolute;
    opacity: 0;
    width: 240px;
    font-size: 14px;
}

.VideoContainer
{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 50px;
}

.VideoContainer h2
{
    color: white;
    font-size: 32px;
}

.VideoContainer p
{
    color: white;
}

.VideoTextContainer
{
    display: flex;
    flex-direction: column;

    width: 30%;
    height: 275px;
}


@media(max-width: 950px)
{
    .TextContainer h2
    {
        opacity: 0;

        margin: 0;
        padding: 0;

        width: 60%;
        font-size: 42.5px;
        color: white;

        text-shadow: 1px 1px 2px black;
    }

    .TextContainer p
    {
        opacity: 0;
        margin: 0;
        padding: 0;

        color: white;
        width: 60%;
    }
}

@media(max-width: 1000px)
{
    .VideoTextContainer
    {
        border-top: 1px solid rgb(175, 175, 175);
        border-bottom: 1px solid rgb(175, 175, 175);
        display: flex;
        flex-direction: column;
    
        width: 30%;
        height: 275px;
    }

    .VideoContainer h2
    {
        color: white;
        font-size: 24px;
    }

    .VideoContainer p
    {
        font-size: 14px;
        color: white;
    }
    
}