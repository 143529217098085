.Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    flex-wrap: nowrap;
    white-space: nowrap;

    position: fixed; 
    top: 0;
    left: 0;
    right: 0;

    padding-left: 10px; 
    padding-right: 10px;
    z-index: 100000;
    height: 80px;

    overflow-y: hidden;
    overflow-x: auto; /* Enable horizontal scrolling */

    gap: 20px;

    background-color: rgb(0, 0, 0, 1); /* Ensures it stands out visually */
    scrollbar-width: thin; /* Firefox: thin scrollbar */
    scrollbar-color: #888 #222; /* Firefox: colors */
    border-bottom: 1px solid #222;
}

/* Webkit Scrollbar Customization (Chrome, Edge, Safari) */
.Container::-webkit-scrollbar {
    height: 6px; /* Thin scrollbar */
}

.Container::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb color */
    border-radius: 10px;
}

.Container::-webkit-scrollbar-track {
    background: #222; /* Track color */
}

.Col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    min-width: 100px; /* Ensures consistent width */
    padding: 5px; /* Adds some padding for touch areas */
}


.Icon
{
    font-size: 16px !important;
}

.Col h2 {
    padding: 0;
    margin: 0;
    color: white;
}

.Col:hover {
    background-color: rgba(164, 164, 164, 0.3);
}

/* Mobile-Specific Styles */
@media screen and (max-width: 768px) {
    .Container {
        height: 60px; /* Adjust height for smaller screens */
        gap: 10px; /* Reduce gap */
    }

    .Col {
        min-width: 80px; /* Reduce width */
    }

    .Col h2 {
        font-size: 12px; /* Smaller font size */
    }
}