
.Container
{
    opacity: 1; 
    overflow-x: hidden;

    position: fixed;

    height: 100vh; 
    z-index: 5;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    width: 20%; 
    background: #202020; 
    border-right: 1px solid #ababab;
}

.CanvasStyles
{
    height: 100px;
    padding-bottom: 100px;
}

/* For WebKit Browsers (Chrome, Edge, Safari) */
.Container::-webkit-scrollbar {
    width: 12px; /* Width of vertical scrollbar */
    height: 12px; /* Height of horizontal scrollbar */
}

.Container::-webkit-scrollbar-track {
    background: #303030; /* Background of the track */
    border-radius: 10px; /* Optional: rounded corners */
}

.Container::-webkit-scrollbar-thumb {
    background: #606060; /* Scrollbar color */
    border-radius: 10px; /* Optional: rounded scrollbar thumb */
    border: 2px solid #202020; /* Optional: add a gap between thumb and track */
}

.Container::-webkit-scrollbar-thumb:hover {
    background: #909090; /* Change on hover */
}

/* For Firefox */
.Container {
    scrollbar-color: #606060 #303030; /* thumb color | track color */
    scrollbar-width: thin; /* auto, thin, or none */
}

/* Optional Fallback for Older Browsers (not standardized) */
.Container {
    scrollbar-face-color: #606060;
    scrollbar-track-color: #303030;
    scrollbar-shadow-color: #202020;
}