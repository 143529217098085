@import url("https://use.typekit.net/ehh5dmk.css");
@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@303&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=Protest+Strike&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Oxanium:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.roboto
{
  font-family: 'Roboto', sans-serif;
}

.oxanium {
  font-family: "Oxanium", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.superscript { position: relative; top: -0.5em; font-size: 80%; }

.titleText
{
  font-family: 'Oswald', sans-serif;
}

.Anta
{
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.AileronHeavy
{
  font-family: "aileron", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.Tilt
{
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.header
{
  font-family: 'Roboto Mono', monospace;
}

.opensans
{
  font-family: 'Open Sans', sans-serif;
}

.Montserrat 
{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.PS
{
  font-family: "Protest Strike", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bodyBlack
{
  .body
  {
    background: black;
  }
}

/* Apply the animation to your element */
.ShinyText {
  color: #fff; /* Fallback: Load with white text */
  background: -webkit-linear-gradient(45deg, #f06, #aef, #f06, #aef);
  background: linear-gradient(45deg, #f06, #aef, #f06, #aef);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; /* For a transparent text color */
  animation: gradientAnimation 3s linear infinite;
  display: inline-block; /* To allow the element to take the size of the content */
  font-family: 'Oswald', sans-serif;
}

/* Optional: Add a glossy effect with a pseudo-element */
.ShinyText::after {
  content: '';
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), transparent 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: glossAnimation 2s linear infinite;

}

/* Keyframes for the gloss effect */
@keyframes glossAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}

.glass
{
  /* From https://css.glass */
  background: rgba(91, 91, 91, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(91, 91, 91, 0.3);
}

.glassX
{
  /* From https://css.glass */
  background: rgba(91, 91, 91, 0.7);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(91, 91, 91, 0.3);
}

.glass2
{
  /* From https://css.glass */
  background: #292929;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(91, 91, 91, 0.3);
}

.glass3
{
  /* From https://css.glass */
  background: rgba(91, 91, 91, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(91, 91, 91, 0.3);
}