/* .JoinCommunityGIF */

.JoinCommunityGIFContainer
{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1592741000;
    display: flex;
    flex-direction: column;
    pointer-events: none;

}

.JoinCommunityGIF
{
    margin-top: 65vh;
    margin-left: 8vw;
    width: 8vw;
}

@media(max-aspect-ratio: 20/10)
{
    .JoinCommunityGIF
    {
        margin-top: 64vh;
        margin-left: 8vw;
        width: 8vw;
    }
}

@media(max-aspect-ratio: 18.5/10) and (min-aspect-ratio: 17/10)
{
    .JoinCommunityGIF
    {
        margin-top: 62vh;
        margin-left: 8vw;
        width:  10vw;
    }
}

@media(max-aspect-ratio: 17/10) and (min-aspect-ratio: 15.5/10)
{
    .JoinCommunityGIF
    {
        margin-top: 62vh;
        margin-left: 8vw;
        width: 8vw;
    }
}

@media(max-aspect-ratio: 15.5/10) and (min-aspect-ratio: 14/10)
{
    .JoinCommunityGIF
    {
        margin-top: 61vh;
        margin-left: 8vw;
        width: 9vw;
    }
}


@media(max-aspect-ratio: 14/10) and (min-aspect-ratio: 12.5/10)
{
    .JoinCommunityGIF
    {
        margin-top: 59vh;
        margin-left: 8vw;
        width: 10vw;
    }
}

@media(max-aspect-ratio: 12.5/10)
{
    .JoinCommunityGIF
    {
        margin-top: 57vh;
        margin-left: 8vw;
        width: 10vw;
    }
}