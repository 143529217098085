.ItemSegment
{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    gap: 5px;
    color: white;

}

.Line
{
    margin: 0;
    padding: 0;

    width: 100%;
    height: .25px;
    background-color: rgb(57, 57, 57);
}

.ItemSegmentTitleContainer
{
    display: flex;
    align-items: center;
    width: 100%;

    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.ItemSegmentTitleContainer:hover
{
    background-color: grey;
    cursor: pointer;
}

.Heading
{
    padding-left: 20px;
}

.Caret
{
    height: 12px;
    width: 12px;
    margin-right: 20px;
}

.CaretOpen
{
    transform: rotateZ(90deg)
}