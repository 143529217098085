

.SubHeading
{
    color: rgb(211, 211, 211);
    text-align: center;
    opacity: 1;
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100%;
    padding-bottom: 20px;
}

.Left
{
    text-align: left !important;
}

@media(max-width: 1000px)
{
    .SubHeading
    {
        font-size: 13px;
    }
}