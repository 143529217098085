@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

.IntroScrollWrapper {
    width: 100vw; /* Ensure full viewport width */
    background: #0e0e0f;

    justify-content: flex-start; /* Align items to the start */
    margin-top: 0;
}

.IntroScrollMain {
    padding-top: 25vh;
    display: flex;
    position: relative;
    white-space: nowrap; /* Prevent wrapping of text */
    left: 0;
}

.IntroScrollMain h2 {
    font-family: 'Staatliches', cursive;
    font-size: 10vw;
    flex-shrink: 0;
    padding-right: 0.3em;
    padding-left: 0.3em;
    color: #c4c4c4;
    margin: 0;
}
