

.Container
{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-x: hidden;
    overflow-y: hidden;

    z-index: 10000;
    background-color: black;

    position: absolute;
}

.HeaderSpace
{
    height: 110px;
}

.FooterContainer
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border: 1px solid rgb(97, 97, 97);
}

.Col
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}