.Sub
{
    margin: 0;
    padding: 0;
    color: rgb(185, 185, 185);
}

.SubWhite
{
    margin: 0;
    padding: 0;
    color: white;
}

.ListStyle
{
    margin: 0;
    padding: 0;
    margin-top: -5px;
    margin-left: 20px !important;
    font-size: 16px;
}