
.Container
{
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    justify-content: center;

    z-index: 1000000000;
    width: 100vw;
    height: 3vh;
    gap: 30px;
    top: 0;
    opacity: 0;
}

.Item
{
    color: rgb(222, 221, 221);
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
}

.Item:hover
{
    color: rgb(159, 158, 158);
}

.Image
{
    height: 12px;
    width: 12px;

    margin-top: 7.5px;

    cursor: pointer;
}

.Container2
{
    opacity: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;

    z-index: 100;
    width: 100vw;
    height: 3vh;
    top: 0;
    gap: 0px;
}

.ItemsContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
    margin-top: 0px;
    width:  100%;
}

@media(max-width: 940px) and (min-width: 780px)
{
    .Container
    {
        gap: 20px;
    }
    .Item{
        font-size: 10px;
    }
}

@media(max-width: 780px) and (min-width: 720px)
{
    .Item
    {
        color: #cbcbcb; 
    }
    .Container2
    {
        background-color: none;
    }
    .Container 
    {
        gap: 10px;
    }
    .Item{
        font-size: 9px;
    }
}

@media(max-width: 720px) and (min-width: 551px)
{

    .Image
    {
        height: 10px;
        width: 10px;
    }
    .Container2
    {
        background-color: none;
    }

    .Item
    {
        color: #cbcbcb;
        font-size: 8px;
    }

    .Container
    {
        gap: 10px;
    }
}

@media(max-width: 550px)
{
    .Image:nth-child(1)
    {
        margin-left: 0px;
    }
    .Container2
    {
        background-color: none;
    }
    .Item
    {
        color: #cbcbcb;
        font-size: 10px;
    }
}