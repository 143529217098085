
.ImageContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0%;
    position: absolute;

    background-color: rgb(107, 107, 107);

    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.InnerImg
{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}