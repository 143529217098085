.Heading
{
    color: rgb(214, 214, 214);
    font-size: 12px;

    padding-left: 40px;
}

.Line
{
    margin: 0;
    padding: 0;

    width: 100%;
    height: .25px;
    background-color: rgb(43, 43, 43);
}

.Container
{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    img{
        width: 14px;
        margin-right: 40px;
    }
}


.Container:hover
{
    background-color: grey;
    cursor: pointer;
}