
/* Trap Bold Font-Face Declarations */
@font-face {
    font-family: 'TrapBold';
    src: url('../assets/fonts/trap/Trap-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'TrapBold', sans-serif;
}

/* Helvetica Neue Font-Face Declarations */
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueBlack.otf') format('opentype');
    font-weight: 900; /* Black */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueBlackItalic.otf') format('opentype');
    font-weight: 900; /* Black Italic */
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueBold.otf') format('opentype');
    font-weight: bold; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueHeavy.otf') format('opentype');
    font-weight: 800; /* Heavy */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueHeavyItalic.otf') format('opentype');
    font-weight: 800; /* Heavy Italic */
    font-style: italic;
}



@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueLight.otf') format('opentype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueLightItalic.otf') format('opentype');
    font-weight: 300; /* Light Italic */
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueMedium.otf') format('opentype');
    font-weight: 500; /* Medium */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueMediumItalic.otf') format('opentype');
    font-weight: 500; /* Medium Italic */
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueRoman.otf') format('opentype');
    font-weight: normal; /* Roman (Regular) */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueThin.otf') format('opentype');
    font-weight: 100; /* Thin */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueThinItalic.otf') format('opentype');
    font-weight: 100; /* Thin Italic */
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueUltraLight.otf') format('opentype');
    font-weight: 200; /* Ultra Light */
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/helvetica-neue-5/HelveticaNeueUltraLightItalic.otf') format('opentype');
    font-weight: 200; /* Ultra Light Italic */
    font-style: italic;
}

/* Applying the font */
.HN {
    font-family: 'HelveticaNeue', sans-serif;
}