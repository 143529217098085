
.SectionContainer
{
    height: 700vh; 
    width: 100vw;
    background: #6d4a92;
    display: flex;
    flex-direction: column;
}

.CanvasContainer
{
    opacity: 0;
    z-index: 200;
    width: 100vw; 
    height: 100vh;  
    background: #262a33;
}

.CanvasStyles
{
    opacity: 0;
    width: 100%; 
    height: 100%; 
}

.S2Container
{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.S2Container h2
{

    color: white;
    left: 12.5%;
    width: 35%;
    font-size: 40px;
    text-shadow: 1px 1px 2px black;
    position: absolute;
}

.S2Container a
{
    text-shadow: none;
}

.FeaturesContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.Feature
{
    background-color: #2323F1;
    align-self: flex-end;
    position: absolute;

    width: 40vw;
    height: 60vh;
}

.FeaturesContainer h2
{
    position: absolute;
    right: 40%;
    color: white;
    width: 35%;
    font-size: 30px;
    text-shadow: 1px 1px 2px black;
}

.LLCanvasContainer
{
    position: absolute;
    justify-self: flex-start;
    align-self: flex-start;
    background-color: red;
    width: 30vw;
    height: 30vh;
}

.FeaturesContainerSplitBackground
{
    width: 100%;
    height: 100%;
    background-color: beige;

    border-top: 100px solid transparent;
    border-right: 100px solid transparent;
}

.ShowcaseContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;

    padding-top: 35px;
    padding-bottom: 35px;
    width: 40vw;
    border-radius: 5px;

    gap: 50px;

    top: 50%;
    transform: translateY(-50%);
    left: 52.5%;
    opacity: 0;

    background: rgba( 0, 0, 0, 0.1 );
    backdrop-filter: blur( 12px );
    -webkit-backdrop-filter: blur( 12px );
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
    color: white;
}

.SCCH2
{
    padding: 0;
    margin: 0;
    font-size: 32px;
    font-weight: bolder;
    display: inherit;
    opacity: 0;
    color: white;
}

.ShowcaseContainer p
{
    padding: 0;
    margin: 0;
    opacity: 0;
    width: 80%;
    font-size: 18px;
    text-align: center;
    color: white;
    line-height: 1.35;
}

.OptionsContainer 
{
    border: 1px solid rgb(106, 106, 106);
    opacity: 0;

    margin-top: 10px;

    background-color: rgb(69, 69, 69);
    width: 90%;
    height: 80px;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.OptionsContainer:hover
{
    background-color: rgb(97, 97, 97);
    cursor: pointer;
}

.OCH2
{
    text-shadow: 1px 1px black;
    position: absolute;
    text-align: left;
    justify-self: flex-start;
    opacity: 1;
    margin-right: 10px; /* Add some space between text and image */
    font-size: 17.5px;
    width: 70%;
    color: white;
    padding: 0;
    margin: 0;
}

.OptionsContainer img 
{
    height: 25px; 
    vertical-align: middle;
    justify-self: flex-end;

    position: absolute;
    margin-left: 20px;
    right: 10%;
}

@media(max-width: 1375px) and (min-width: 1230px)
{
    .OCH2
    {
        font-size: 15.5px;
    }

    .SCCH2
    {
        font-size: 30px;
    }

    .ShowcaseContainer p
    {
        font-size: 16px;
    }
}

@media(max-width: 1230px) and (min-width: 1090px)
{
    .OCH2
    {
        font-size: 13.5px;
    }
    .SCCH2
    {
        font-size: 26px;
    }

    .ShowcaseContainer p
    {
        font-size: 16px;
    }
}

@media(max-width: 1090px) and (min-width: 950px)
{
    .OCH2
    {
        font-size: 12px;
    }
    .OptionsContainer img 
    {
        height: 20px; 
    }
    .SCCH2
    {
        font-size: 23.5px;
    }
    .ShowcaseContainer p
    {
        font-size: 16px;
    }
}

@media(max-width: 950px)
{
    .OCH2
    {
        font-size: 10.5px;
    }
    .OptionsContainer img 
    {
        height: 20px; 
    }
    .SCCH2
    {
        font-size: 21px;
    }
    .ShowcaseContainer p
    {
        font-size: 15px;
    }
}