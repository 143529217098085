

.Container
{
    width: 80%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    flex-direction: column;
    background-color: black;
}

.SmallHeader
{
    font-size: 16px !important;
    margin-top: 10px !important;
}

.Row
{
    gap: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 100px;
    justify-content: center;
}

.ImageButtons
{
    height: 50px;
    width: 50px;
}

.DividerLine
{
    margin: 0;
    padding: 0;
    height: 1px;
    border: none;

    margin-top: 60px;
    margin-left: -20px;
    margin-bottom: 30px;
    width:  calc(100% + 40px);

    background-color: rgb(56, 56, 56);
}