body 
{
  background: #0e0e0f;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special
{
  overflow-y: scroll;
}

.noPreload
{
  visibility: hidden;
  overflow-y: hidden;
  opacity: 0;
}

::-webkit-scrollbar
{
  width: 10px;
}

::-webkit-scrollbar-track
{
  background-color: #202021;
}

::-webkit-scrollbar-thumb
{
  background-color: #767676;

  cursor: grabbing;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover
{
  background-color: #924c4c;
}

/*firefox*/
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin; /* This rule will only apply in Firefox */
  }
}

/* Disable scrollbar for mobile (touch devices) */
@media (pointer: coarse) {
  /* Applies only to devices with coarse pointers (touch screens) */
  body, .special {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  body::-webkit-scrollbar,
  .special::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
}