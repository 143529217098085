
.Container
{
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0;
}

.MatrixContainer
{
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.CanvasContainer
{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 3;
}

.TextContainer
{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

}

.TextContainerInner
{
    margin-top: 40vh;
    height: 100vh;
    width: 40vw;

    left: 17.5%;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.TextContainerInner h2
{
    padding: 0;
    margin: 0;
    text-align: center;

    color: white;
    font-size: 40px;
    text-shadow: 1px 1px 2px black;
}

.ButtonContainer
{
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 20px;
    width: 100%; 
    margin-top: 40px;
}

.ScrollCode
{
    padding: 0;
    margin: 0;

    background-color: rgb(52, 52, 52);

    overflow-y: scroll;

    height: 50vh;
    width: 100%;
}

.FrameContainer
{
    position: absolute;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;
    border: 1px solid rgb(95, 95, 95);
    top: 0%;
    width: 60vw;
    height: 80vh;
    left: 15%;
    margin-top: 10vh;
    align-items: center;
    justify-content: center;
    z-index: 3911241;
}

.CloseFrameContainer
{
    position: absolute;
    color: white;
    right: 3%;
    cursor: pointer;

}

@media(max-width: 1375px) and (min-width: 1230px)
{
    .TextContainerInner h2
    {
        font-size: 36px;
    }   
}

@media(max-width: 1230px) and (min-width: 1090px)
{
    .TextContainerInner h2
    {
        font-size: 36px;
    } 
}

@media(max-width: 1090px) and (min-width: 950px)
{
    .TextContainerInner h2
    {
        font-size: 32px;
    } 
    .TextContainerInner
    {
        width: 50vw;
    }
}

@media(max-width: 950px)
{
    .TextContainerInner h2
    {
        font-size: 32px;
    } 
    .TextContainerInner
    {
        width: 50vw;
    }
}