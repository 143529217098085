.Container
{
    width: 100%;
    height: 100%;

    background-color: rgb(40, 40, 40);
}

.Desktop
{
    width: 80%;
    margin-left: 20%;
}

.Image
{
    width: 75%;
    height: 75%;
}

.Mobile
{
    width: 100%;
}

.ContentContainer
{
    display: flex;
    flex-direction: column;

    align-items: center;

    padding-bottom: 40px;
    background-color: rgb(40, 40, 40);
}

.InnerContainer
{
    width: 85%;
    height: 100%;

    align-items: center;
}

.PageHeader
{
    text-align: left;
    color: white;
    font-size: 50px;
}

.Pg
{
    color: rgb(224, 224, 224);
    font-size: 20px;
    line-height: 1.4;
}

.Header
{
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.H2
{

    color: white;
    font-size: 24px;
    font-weight: bold;
}

.Ul
{
    font-size: 20px;
}

.Space
{
    height: 20px;
}

.White
{
    color: white;
}

.Smaller
{
    color: rgb(224, 224, 224);
    font-size: 16px;
    margin-left: 20px;
}

.Li
{
    margin-top: 10px;
    margin-bottom: 10px;
}

.BigLi
{
    font-size: 23;
    margin-top: 10px;
    margin-bottom: 10px;
}


.IFrameContainer
{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 55vh;
    margin-top: 40px;
    margin-bottom: 40px;
}

.LongImage
{
    max-width: 60vw;
}

.IFrameContainerBig
{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.Center
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Img
{
    max-width: 75%;
}

@media(max-width: 1000px) and (min-width: 700px)
{
    .PageHeader
    {
        font-size: 30px;
    }
    .Header
    {
        font-size: 24px;
    }
    .Pg
    {
        font-size: 20px;
    }
    .IFrameContainer
    {
        height: 55vh;
    }
    .Img
    {
        max-width: 600px;
    }
}

@media(max-width: 700px) and (min-width: 490px)
{
    .PageHeader
    {
        font-size: 30px;
    }
    .Header
    {
        font-size: 24px;
    }
    .Pg
    {
        font-size: 20px;
    }
    .IFrameContainer
    {
        height: 45vh;
    }
    .Img
    {
        max-width: 500px;
    }
}
@media(max-width: 490px) and (min-width: 400px)
{
    .PageHeader
    {
        font-size: 30px;
    }
    .Header
    {
        font-size: 24px;
    }
    .Pg
    {
        font-size: 20px;
    }
    .IFrameContainer
    {
        height: 40vh;
    }
    .Img
    {
        max-width: 400px;
    }
}
@media(max-width: 400px)
{
    .PageHeader
    {
        font-size: 30px;
    }
    .Header
    {
        font-size: 24px;
    }
    .Pg
    {
        font-size: 20px;
    }
    .IFrameContainer
    {
        height: 35vh;
    }
    .Img
    {
        max-width: 350px;
    }
}