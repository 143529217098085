.Container
{
    height: 740vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0; /* Start fully transparent */
    transition: opacity 0.5s ease; /* Optional: Ensure smooth rendering */
    position: relative; /* Ensure it renders correctly */
    overflow: hidden; /* Prevent child elements from affecting scrollTrigger behavior */
}

.MoonBg
{
    width: 100vw;
    height: 100%;
    opacity: 0;
    position: absolute;
}

.Moon
{
    background-image: radial-gradient(circle at top, #282828 3%, #1b1b1b 50%, #000 70%);
    position: absolute;
    z-index: 100;
    width: 200px;
    height: 200px;
}

.ContentContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 0;
    z-index: 10000;
    width: 80%;
    height: 80vh;
}

.TwoItemsContainer
{
    margin-top: -50px;
    padding: 0px;
    opacity: 0;
    width: 80%;
    gap: 3%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.HeadingMain
{
    color: white;
    text-align: center;

    opacity: 1;
    font-size: 50px;
    width: 70%;
    margin-top: 80vh;

    /* Add faint neon glow */
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.1);
}

.SectionX_Section2Container
{
    opacity: 0;
    margin-top: 80vh;
    width: 100vw;
    height: 80vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
}

.Header3
{
    color: white;
    text-align: center;

    opacity: 1;
    font-size: 50px;
    width: 60%;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.1);
}

.Header4
{
    color: white;
    text-align: center;

    opacity: 1;
    font-size: 45px;
    width: 80%;
    margin-bottom: 0;
}

.Header4Revised
{
    color: white;
    text-align: center;

    opacity: 1;
    font-size: 25px;
    width: 80%;
    margin-bottom: 10px;
}

.Header4Sub
{
    color: rgb(211, 211, 211);
    text-align: center;
    opacity: 1;
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100%;
    padding-bottom: 20px;
}

.Header4Sub2
{
    color: rgb(211, 211, 211);
    text-align: center;
    opacity: 1;
    margin: 0;
    padding: 0;
    font-size: 20px;
    width: 80%;
    padding-bottom: 20px;
}

.ERCContainer
{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    height: 100%;
    background-color: rgb(47, 47, 47, 0.6);
    backdrop-filter: blur(6.1px);
    -webkit-backdrop-filter: blur(6.1px);
    z-index: 1028172;
    border: 1px solid #464646;
    border-radius: 20px;
    
}

.InnerItem
{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header2
{
    color: white;
    margin: 0;
    padding: 0;
}

.Header2Hoverable
{
    color: rgb(232, 232, 232);
    margin: 0;
    padding: 0;
    margin-left: 20px;
}

.Pg
{
    margin: 0;
    padding: 0;
    margin-top: 5px;
    color: rgb(232, 232, 232);
}

.Pg2
{
    margin: 0;
    padding: 0;
    margin-top: 5px;
    color: rgb(232, 232, 232);
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.Img
{
    width: 256px;
    height: 256px;
}


.Img2
{
    width: 76px;
    height: 76px;
    position: absolute;
    right: 7.5%;
}

.NiceColumn
{

    z-index: 10000202991;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.NiceColumn2
{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;

    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(6.1px);
    -webkit-backdrop-filter: blur(6.1px);

}


.NiceRow, .NiceRowHoverable
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.NiceRowHoverable
{
    background-color: rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    height: 76px;
    justify-content: flex-start;
}

.NiceRowHoverable:hover
{
    background-color: rgba(84, 84, 84, 0.2);
    cursor: pointer;
}


@media(max-width: 1530px) and (min-width: 1315px)
{
    .ERCContainer
    {
        width: 70%;
        height: 100%;
    }
}

@media(max-width: 1315px) and (min-width: 915px)
{
    .HeadingMain, .Header3
    {
        font-size: 35px;
        width: 70%;
    }
    .Header2Hoverable
    {
        font-size: 20px;
    }

    .ERCContainer
    {
        width: 75%;
        height: 65%;
    }

}

@media(max-width: 1050px) and (min-width: 915px)
{
    .Img
    {
        width: 200px;
        height: 200px;
    }

    .Header2Hoverable
    {
        font-size: 15px;
    }
    .Header4
    {
        font-size: 25px;
    }
    .Header2
    {
        font-size: 20px;
    }
    .TwoItemsContainer
    {
        width: 100%;
    }
}

@media(max-width: 915px)
{
    .HeadingMain, .Header3
    {
        font-size: 35px;
        width: 75%;
    }
    .Header2Hoverable
    {
        font-size: 15px;
    }

    .ERCContainer
    {
        width: 75%;
        height: 65%;
    }
    .Header4
    {
        font-size: 25px;
    }
    .Header2
    {
        font-size: 18px;
    }
    .TwoItemsContainer
    {
        width: 100%;
    }
    .Img
    {
        width: 180px;
        height: 180px;
    }
}

@media(max-width: 1000px)
{
    .Header4Sub
    {
        font-size: 14px;
    }
}