.Container
{
    position: absolute;
    right: 0; 
    bottom: 0;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    z-index: 100010;
    padding: 2%;
    scale: .75;
}

.Header
{
    color: white; 
    text-align: center;
}

.Sub
{
    margin: 0;
    padding: 0;
    color: white;
}

.Form
{
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.Email
{
    width: 20vw;
    margin-top: 5vh;
}

.Password
{
    width: 20vw;
    margin-top: 2vh;
}

.SubmitButton
{
    margin-top: 2vh;
    padding-bottom: 0;
    margin-bottom: 0;
}

@media(max-width: 800px)
{
    .Container
    {
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        height: 30vh;
    }

    .Header
    {
        margin: 0;
        padding: 0;;
    }

    .Email
    {
        width: 20vw;
        margin-top: 1vh;
    }

    .Password
    {
        width: 20vw;
        margin-top: 1vh;
    }
}