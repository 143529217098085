
.InteractiveContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 80vh;
    width: 80%;
    height: 80vh;
    border-radius: 20px;

    background-color: rgb(47, 47, 47, 0.6);
    border: 1px solid rgb(85, 85, 85);
    backdrop-filter: blur(6.1px);
    -webkit-backdrop-filter: blur(6.1px);
    
    opacity: 0;
}

.ContractTextWrapper
{
    display: flex;
    flex-direction: column;
    opacity: 0;
}

@keyframes shadowPulse {
    0% {
      box-shadow: 2px 2px 4px rgb(69, 69, 69);
    }
    100% {
      box-shadow: 2px 2px 8px white; /* You can adjust the blur radius for a more dramatic effect */
    }
  }

@keyframes shadowWave {
    0% {
      box-shadow: 2px 2px 4px rgb(69, 69, 69);
    }
    25% {
      box-shadow: 2px 2px 6px white;
    }
    50% {
      box-shadow: 2px 2px 8px rgb(69, 69, 69);
    }
    75% {
      box-shadow: 2px 2px 6px white;
    }
    100% {
      box-shadow: 2px 2px 4px rgb(69, 69, 69);
    }
  }

.IntroWrapper
{  
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
    top: 70%;
    transform: translateY(-50%);
}

.Drop
{
    top: 50% !important;
}

.IntroWrapperRow
{  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: 60%;
    top: 70%;
    transform: translateY(-50%);
}

.FullCol, .FullColStartHidden
{
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    border-top: 1px solid rgb(85, 85, 85);
}

.FullColStartHidden
{

    position: absolute;
}

.TotalContainer
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LiHide
{
    opacity: 0;
    position: absolute;
}

.FullCol2
{
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
}


.StartHidden
{
    opacity: 0;
    pointer-events: none;
}

.ImageContainer
{
    display: flex;
    flex-direction: column;
    position: relative;

    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    z-index: 1000000;
    width: 100%;
    height: 40%;
    background-color: rgb(50, 50, 50);
    height: 0px;
}

.ContractWrapper
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    top: 50%;
}



.SimpleText, .SimpleText2
{
    margin: 0;
    padding: 0;
    color: rgb(164, 164, 164);
    font-style: italic;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
}

.SimpleText2
{
    position: absolute;
    opacity: 0;
}

.HBC
{
    width: 128px;
    height: 128px;
    opacity: 0;
}

.Bob
{
    width: 82px;
    height: 82px;
}

.Logic
{
    width: 82px;
    height: 82px;
}

.Line
{
    position: absolute;
    width: 5vw;
    height: 2px;
    color: white;
    background-color: rgb(152, 152, 152);
    border: 1px solid rgb(121, 121, 121);
    top: 50%;
    transform: translateY(-50%);
    left: 30%;
}

.LogicContainer
{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%) translateX(-50%);
    left: 20%;
    top: 50%;
    opacity: 0;

    width: 128px;
}

.BobContainer
{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%) translateX(-50%);
    left: 30%;
    top: 50%;
    opacity: 0;

    width: 128px;
}

.LogicShift
{
    left: 80%;
}

.LogicContainer p
{
    text-align: center;
    opacity: 0;
    font-size: 10px;
    color: white;
}

.ExplanationContainer
{
    opacity: 0;
    pointer-events: none;

    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);

    width: 40%;
    height: 90%;
    background-color: rgb(75, 75, 75);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Title
{
    font-size: 20px;
    width: 80%;
    color: white;
}

.BasicText
{
    margin: 0;
    color: rgb(255, 255, 255);
    width: 80%;
    font-size: 16px;
}

.BasicText2
{
    margin: 0;
    margin-top: 20px;
    color: rgb(179, 179, 179);
    width: 300px;
}

.BasicText3
{
    margin: 0;
    margin-top: 2.5px;
    color: rgb(249, 249, 249);
    width: 300px;
}


.Button
{
    margin: 0;
    background-color: white;
    border: none;
    width: 65%;
    border-radius: 20px;
    margin-top: 10px;
}

.Button:hover
{
    background-color: rgb(186, 186, 186);
    cursor: pointer;
}

.TextContainer, .TextContainer2
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TextContainer2
{
    margin: 0;
    pointer-events: none;
    position: absolute;
    opacity: 0;
}

.Hidden
{
    opacity: 0;
    pointer-events: none;
}

.HiddenAbs
{
    opacity: 0;
    position: absolute;
}

.StorageItems
{
    opacity: 0;
    pointer-events: none;
}

.StorageContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    border: 1px solid rgb(85, 85, 85);
    opacity: 0;
    width: 50%;
    height: 99%;
    gap: 20px;
}

.FullCol2Long
{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid rgb(85, 85, 85);
    background-color: rgb(33, 33, 33);
    height: 60%;
    width: 80%;
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: hidden;
    overflow-y: scroll;
}


.Terminal
{
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    background-color: rgb(29, 29, 29);
    width: 70%;
    height: 40px;
    margin-bottom: 5px;
}

.Terminal p 
{
    position: absolute;
    opacity: 0;
    font-size: 12px;
}

.Row
{
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Column
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media(max-width: 1250px)
{
    .InteractiveContainer
    {
        position: absolute;
        margin-top: 80vh;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        height: 80vh;
    }

    .Logic
    {
        width: 100px;
        height: 100px;
    }

    .HBC
    {
        width: 100px;
        height: 100px;
        opacity: 0;
    }

    .LogicContainer p
    {
        opacity: 0;
        font-size: 10px;
        color: white;
    }

}

@media(max-width: 1000px)
{
    .Title
    {
        font-size: 16px;
        width: 80%;
        color: white;
    }
    .BasicText
    {
        font-size: 14px;
    }
}

@media(max-width: 850px)
{
    .BasicText2
    {
        margin-left: 20px;
    }

}