.FormContainer
{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.7);

    z-index: 1;

    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 0px !important;
}

.Form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 10px;
}

.LabelStyle
{
    color: white;
}

.Header
{
    color: white;
}