

.Container
{
    position: fixed;

    margin-top: 10px;
    margin-left: 30px;

    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.Container:hover
{
    background-color: rgb(174, 174, 174);
}

.Hamburger
{
    width: 15px;
    height: 15px;
}